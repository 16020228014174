import React,{useState,useEffect} from 'react'
import Particles from "react-tsparticles";
const ParticleLanding = () => {
const [color, setcolor] = useState('#111');
  // const particlesInit = (main) => {
  //   // console.log(main);
  // };

  // const particlesLoaded = (container) => {
  //   // console.log(container);
  // };
  var r = document.querySelector(':root');
  var rs = getComputedStyle(r);
  var currentColor=rs.getPropertyValue('--dark-color')
  useEffect(() => {
    setcolor(currentColor)
    
  }, [currentColor]);


  return (
    <div>
      <Particles
      id="tsparticles"
      options={{
        background: {
          color: {
            value: "transparent",
          },
        },
        fpsLimit: 60,
        interactivity: {
          events: {
            
            onHover: {
              enable: true,
              mode: "repulse",
            },
            resize: true,
          },
          modes: {
            bubble: {
              distance: 300,
              duration: 24,
              opacity: 0.2,
              size: 40,
            },
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 200,
              duration: 1,
            },
          },
        },
        particles: {
          color: {
            // value: '#b8b693',                      
            value: '#d0c4de',            
          },
          links: {
            // color: '#b8b693',      
            color: '#d0c4de',            
            distance: 150,
            enable: true,
            opacity: 0.6,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            direction: "none",
            enable: true,
            outMode: "bounce",
            random: false,
            speed: 2,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 50,
          },
          opacity: {
            value: 0.6,
          },
          shape: {
            type: "circle",
          },
          size: {
            random: true,
            value: 5,
          },
        },
        // detectRetina: true,
        
      }}
     
    />

    </div>
  )
}

export default ParticleLanding