import React from 'react'
import { Link } from 'react-router-dom';
import './Missing.css'
import $ from 'jquery';

const Missing = () => {
  $(function () {
    $(".btn-6")
      .on("mouseenter", function (e) {
        var parentOffset = $(this).offset(),
          relX = e.pageX - parentOffset.left,
          relY = e.pageY - parentOffset.top;
        $(this).find("span").css({ top: relY, left: relX });
      })
      .on("mouseout", function (e) {
        var parentOffset = $(this).offset(),
          relX = e.pageX - parentOffset.left,
          relY = e.pageY - parentOffset.top;
        $(this).find("span").css({ top: relY, left: relX });
      });
    $("[href=#]").click(function () {
      return false;
    });
  });
  return (
    <section className='paddingSection missingSection'>
      <h1>Oops!</h1>
      <h3>Seems like you got lost with the food</h3>
      <Link to="/" className="btn-6" >Take me back on the trail<span></span></Link>

    </section>
  )
}

export default Missing