import React,{useState} from 'react'
import './Toggler.css'
const Toggler = () => {
    const [dark, setDark] = useState(true);
    var r = document.querySelector(':root');
    var rs = getComputedStyle(r);
    function handleToggle(e)
    {
        setDark(!dark)
        if(dark)
        {
            r.style.setProperty('--light-color', '#111');
            r.style.setProperty('--dark-color', '#fff');
            r.style.setProperty('--background', '#00041b');
            r.style.setProperty('--gray-color', '#cccccc');
            r.style.setProperty('--filter', 'invert()');
            r.style.setProperty('--bright-filter', 'brightness(0) invert()');
        }

        else
        {
            r.style.setProperty('--light-color', '#fff');
            r.style.setProperty('--dark-color', '#111');
            r.style.setProperty('--background', '#fff');
            r.style.setProperty('--gray-color', '#333');
            r.style.setProperty('--filter', 'brightness(100%)');
            r.style.setProperty('--bright-filter', 'brightness(100%)');
        }

    }
  return (
    <div className='togglerWrapper'>
<input id="toggle" class="toggle" type="checkbox" onClick={(e)=>handleToggle(e)}/>
{/* 
<label for="toggle" class="title">Toggle dark mode</label> */}

    </div>
  )
}

export default Toggler