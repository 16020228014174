import React from 'react'
import { Link } from 'react-router-dom'
import './Portfolio.css'
import galleryPR from '../../images/galleryPR1.png'
import galleryMK from '../../images/galleryMK1.png'
import galleryStore from '../../images/galleryStore.jpg'
import galleryCL from '../../images/galleryCL.png'

const Portfolio = () => {
  return (
    <section className='teamSection paddingSection'>
    <div className="teamWrapper">
        <div className="teamRow">
        <div  className="col-md-4 teamCardWrapper teamRowDiv" >
                <div className="teamImg">
                    <img src={galleryPR} alt="" />
                </div>
                <div className="teamCardOverlay">
                    <div className="teamName">PULAO ROAST</div>
                    <div className="navPath teamDesignation">/Brand</div>
                    <div className="teamSocials">
                        
                    </div>
                </div>
            </div>
            <div className="col-md-4 teamCardWrapper teamRowDiv" >
                <div className="teamImg">
                    <img src={galleryStore} alt="" />
                </div>
                <div className="teamCardOverlay">
                    <div className="teamName">Hybrid QSR</div>
                    <div className="navPath teamDesignation">/Store</div>
                    <div className="teamSocials">
                        
                    </div>
                </div>
            </div>
            <div className="col-md-4 teamCardWrapper teamRowDiv" >
               <div className="portfolioWriting">
                   <h1>portf<span>o</span>lio</h1>

               </div>
            </div>
        </div>
        <div className="teamRow">
        <div className="col-md-4 teamCardWrapper teamRowDiv" >
               
            </div>
            <div  className="col-md-4 teamCardWrapper teamRowDiv" >
                <div className="teamImg">
                    <img src={galleryMK} alt="" />
                    <div className="teamCardOverlay">
                    <div className="teamName">the mad knife</div>
                    <div className="navPath teamDesignation">/Brand</div>
                    <div className="teamSocials">
                        
                    </div>
                </div>
                </div>
            </div>
            <div className="col-md-4 teamCardWrapper teamRowDiv" >
                <div className="teamImg">
                    <img src={galleryCL} alt="" />
                    <div className="teamCardOverlay">
                    <div className="teamName">Cravelinks</div>
                    <div className="navPath teamDesignation">/Brand</div>
                    <div className="teamSocials">
                        
                    </div>
                </div>
                </div>
            </div>
           
        </div>
       
    </div>
</section>
  )
}

export default Portfolio