import React from 'react';
import Landing from '../components/Landing/Landing';
import About from '../components/About/About';
import Team from '../components/Team/Team';
import Portfolio from '../components/Portfolio/Portfolio';

const Home = () => {
  window.scrollTo(0,0)
  return (
    <div>
      
      <Landing />
      <About
        title1={'Redefining the'}
        title2={'food industry'}
        header1={'CraveLinks'}
        subHeader1={
          'We are a customer-centric food-tech startup.Our objective is to blend technology with food and beverage to create a viable solution and revamp an age-old industry. That has otherwise been struggling in an evolving region.'
        }
        header2={'OUR STORY'}
        subHeader2={
          'We understand in identifying the ideal balance between exquisite culinary craftsmanship and technological infrastructure.  We hold ourselves accountable for the quality sourcing of ingredients in strict accordance with our industry. At Cravelinks strongly believe food is the only tangible love that is tasted and enjoyed in real-time, something that has no boundaries or religion and we are delighted to spread this love for food across all communities and win people’s hearts by delivering the best craftsmanship with passion. '
        }
        header3={'OUR PEOPLE'}
        subHeader3={
          'At CraveLinks we understand in honouring each other’s distinctions and making sure each one of us feels at home. Our team members are at the forefront of our decisions, all the while encouraging hope, optimism, affirmation, and ongoing learning.'
        }
      />
      <Portfolio />
      {/* <Team /> */}
    </div>
  );
};

export default Home;
