import React, { useState } from 'react';
import './Nav.css';
import { NavLink, useNavigate } from 'react-router-dom';
import LogoLandscape from '../../images/logoLandscape.png';
import BlueLogo from '../../images/blueLogo.png'
import LogoWriting from '../../images/logoWriting.png'
import { HiViewGridAdd } from 'react-icons/hi';
import Toggler from './Toggler';
const Nav = () => {
  const navigate = useNavigate();
  const openNav = () => {
    document.getElementById('rightMenu').classList.add('openMenu');
  };
  const closeNav = () => {
    document.getElementById('rightMenu').classList.remove('openMenu');
  };
  document
    .querySelectorAll('.sidenavLink')
    ?.forEach((link) => link.addEventListener('click', closeNav));
  return (
    <section className='nav content'>
      <div className='logo' onClick={() => navigate('/')}>
        <img src={BlueLogo} className='logoLandscape letterLogo' />
        <img src={LogoWriting} className='logoLandscape writingLogo' alt="" />
     
      </div>
      <div className='rightMenu'>
        <div className='menu'>
          <NavLink
            to='/home'
            className={({ isActive }) => (isActive ? 'active' : 'navLink')}
          >
            Home
          </NavLink>
          <NavLink
            to='/about'
            className={({ isActive }) => (isActive ? 'active' : 'navLink')}
          >
            About
          </NavLink>
          <NavLink
            to='/values'
            className={({ isActive }) => (isActive ? 'active' : 'navLink')}
          >
            Values
          </NavLink>

          <NavLink
            to='/orders'
            className={({ isActive }) => (isActive ? 'active' : 'navLink')}
          >
            Order
          </NavLink>
          {/* <NavLink to="/portfolio" className={({ isActive }) => (isActive ? 'active' : 'navLink')}>Portfolio</NavLink> */}
          <NavLink
            to='/gallery'
            className={({ isActive }) => (isActive ? 'active' : 'navLink')}
          >
            Gallery
          </NavLink>
          <NavLink
            to='/contact'
            className={({ isActive }) => (isActive ? 'active' : 'navLink')}
          >
            Contact
          </NavLink>
        </div>

        <div className='hamMenu' onClick={openNav}>
          <HiViewGridAdd color='var(--dark-color)' />
        </div>
        <div className="togglerWrapper">
        <Toggler/>
      </div>
      </div>
      
      <div className='sideNav' id='rightMenu'>
        <div className='closeBtn' onClick={closeNav}>
          Close <span>X</span>
        </div>
        <div className='sideMenu'>
          <div className='sideMenuItemDiv'>
            <div className='navPath'>/No-place-like-home</div>
            <NavLink
              to='/home'
              className={({ isActive }) =>
                isActive ? 'sideActive sidenavLink' : 'sidenavLink'
              }
            >
              Home
            </NavLink>
          </div>

          <div className='sideMenuItemDiv'>
            <div className='navPath'>/The-backstory</div>
            <NavLink
              to='/about'
              className={({ isActive }) =>
                isActive ? 'sideActive sidenavLink' : 'sidenavLink'
              }
            >
              About
            </NavLink>
          </div>

          <div className='sideMenuItemDiv'>
            <div className='navPath'>/The-ideologies</div>
            <NavLink
              to='/values'
              className={({ isActive }) =>
                isActive ? 'sideActive sidenavLink' : 'sidenavLink'
              }
            >
             Values
            </NavLink>
          </div>

          <div className='sideMenuItemDiv'>
            <div className='navPath'>/enjoy-the-meal</div>
            <NavLink
              to='/orders'
              className={({ isActive }) =>
                isActive ? 'sideActive sidenavLink' : 'sidenavLink'
              }
            >
             Order now
            </NavLink>
          </div>

          <div className='sideMenuItemDiv'>
            <div className='navPath'>/A-place-of-thousand-words</div>
            <NavLink
              to='/gallery'
              className={({ isActive }) =>
                isActive ? 'sideActive sidenavLink' : 'sidenavLink'
              }
            >
              Gallery
            </NavLink>
          </div>

          <div className='sideMenuItemDiv'>
            <div className='navPath'>/Get-in-touch</div>
            <NavLink
              to='/contact'
              className={({ isActive }) =>
                isActive ? 'sideActive sidenavLink' : 'sidenavLink'
              }
            >
              Contact
            </NavLink>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Nav;
