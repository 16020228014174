import './App.css';
import { useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Nav from './components/Navbar/Nav';
import Home from './pages/Home';
import Footer from './components/Footer/Footer';
import AboutPage from './pages/AboutPage';
import PortfolioItem from './pages/PortfolioItem';
import Missing from './components/missing/Missing';
import Contact from './pages/Contact';
import GalleryPage from './pages/GalleryPage';
import ValuePage from './pages/ValuePage';
import Toggler from './components/Navbar/Toggler';
import ParticleLanding from './components/Landing/ParticleLanding';
import Orders from './pages/Orders'
function App() {
  window.addEventListener('load', () => {
    var cursor = document.querySelector('.cursor');

    document.addEventListener('mousemove', function (e) {
      cursor.style.cssText =
        'left: ' + e.clientX + 'px; top: ' + e.clientY + 'px;';
    });
  });

  return (
    <section className='App'>
      <ParticleLanding style={{zIndex:'-21 !important'}}/>
      <div className='cursor'></div>
      <Router>
        <Nav />
        <section className='mainBodyWrapper'>
          <Routes>
            <Route path='/' element={<Orders />} />
            <Route path='/home' element={<Home />} />
            <Route path='/toggler' element={<Toggler />} />
            
            <Route path='/about' element={<AboutPage />} />
            <Route path='/values' element={<ValuePage />} />
            {/* <Route path='/portfolio' element={<Portfolio/>}/> */}
            <Route path='/portfolio/:id' element={<PortfolioItem />} />
            <Route path='/gallery' element={<GalleryPage />} />
            <Route path='/orders' element={<Orders/>}/>
            <Route path='/contact' element={<Contact />} />
            <Route path='*' element={<Missing />} />
          </Routes>
        </section>
        <Footer />
      </Router>
    </section>
  );
}

export default App;
