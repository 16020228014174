import React from 'react';
import './Values.css';
import AltLanding from '../Landing/AltLanding';
import AltAbout from '../About/AltAbout';
import About from '../About/About';
import IconCard from '../IconCard/IconCard';
import founderImage from '../../images/founder.jpg';
import valuesStoreImg from '../../images/valuesStoreimg.jpg';
import valuesStoreImg2 from '../../images/storeInterior.jpg';
import quote from '../../images/quotes.png';
import signature from '../../images/signature.png';

const Values = () => {
  return (
    <section className='values'>
      <AltLanding
        // text='WE’RE FIERCE ABOUT BUILDING A KITCHEN UTOPIA'
        text="We're fierce about building a kitchen utopia"
        img={valuesStoreImg}
      />

      {/* <About
        cname='hybrid-qsr-sec'
        header1='HYBRID QSR TAKEAWAY'
        subHeader1='We have approached a hybrid QSR takeaway outlet where we are currently operating two new utterly delicious brands under the same roof. Providing in-store pickup experience as well as online delivery fulfillment services with our crave link portals.'
       
        header2='OUR STORY'
        subHeader2={`Cravelinks is more than just a food tech company. it’s a platform that seeks to connect foodies with the best culinary experiences across the nation. Bridging culture and community.

        We hold ourselves accountable for the quality sourcing of ingredients in strict accordance with our industry. At Cravelinks strongly believe food is the only tangible love that is tasted and enjoyed in real-time, something that has no boundaries or religion and we are delighted to spread this love for food across all communities and win people’s hearts by delivering the best craftsmanship with passion. We understand identifying the ideal balance between exquisite culinary craftsmanship and technological infrastructure.`}
      
        // header3='OUR PEOPLE'
        // subHeader3='At CraveLinks we understand honoring each other’s distinctions and making sure each of us feels at home. Our team members are at the forefront of our decisions, all the while encouraging hope, optimism, affirmation, and ongoing learning.
        // '
      />   */}
      <div className='paddingSection'>
        <div className='aboutDivWrapper'>
          <div className='aboutDiv col-md-5'>
            <div className='aboutDivTitle'>HYBRID QSR TAKEAWAY</div>
            <div className='aboutDivPara'>
              We have approached a hybrid QSR takeaway outlet where we are currently operating two new utterly delicious
              brands under the same roof. Providing in-store pickup experience as well as online delivery fulfillment
              services with our crave link portals.
            </div>
          </div>

          <div className='aboutDiv col-md-5'>
            <div className='aboutDivTitle'>OUR STORY</div>
            <div className='aboutDivPara'>
              Cravelinks is more than just a food tech company. it’s a platform that seeks to connect foodies with the
              best culinary experiences across the nation. Bridging culture and community. <br /> <br />
              We hold ourselves accountable for the quality sourcing of ingredients in strict accordance with our
              industry. At Cravelinks strongly believe food is the only tangible love that is tasted and enjoyed in
              real-time, something that has no boundaries or religion and we are delighted to spread this love for food
              across all communities and win people’s hearts by delivering the best craftsmanship with passion. We
              understand identifying the ideal balance between exquisite culinary craftsmanship and technological
              infrastructure.
            </div>
          </div>

          <div className='aboutDiv col-md-3'>
            {/* <div className='aboutDivTitle'>OUR STORY</div>
          <div className='aboutDivPara'>Cravelinks is more than just a food tech company. it’s a platform that seeks to connect foodies with the best culinary experiences across the nation. Bridging culture and community.

          We hold ourselves accountable for the quality sourcing of ingredients in strict accordance with our industry. At Cravelinks strongly believe food is the only tangible love that is tasted and enjoyed in real-time, something that has no boundaries or religion and we are delighted to spread this love for food across all communities and win people’s hearts by delivering the best craftsmanship with passion. We understand identifying the ideal balance between exquisite culinary craftsmanship and technological infrastructure.</div> */}
          </div>
        </div>
      </div>

      {/* <AltLanding
        // text='WE’RE FIERCE ABOUT BUILDING A KITCHEN UTOPIA'
        img={valuesStoreImg2}
      /> */}
      <div className='landImg'>
        <img src={valuesStoreImg2} alt='kitchenImg' className='AltImg' />
      </div>

      <div className='foundersMessageWrapper'>
        <div className='founderImageDiv col-md-4'>
          <div className='backgroundDiv'></div>
          <div className='backgroundDiv imageDiv'>
            <img src={founderImage} alt='' />
          </div>
        </div>

        <div className='foundersMessageDiv col-md-6'>
          <div className='foundersMessageTitle'>
            Founder's <span>Message</span>
            <img src={quote} alt='' id='quoteImg' />
          </div>
          <div className='message'>
            <p>
              We strongly believe that how we grow our food is how we grow our future. We are a brand with a
              demonstrated purpose of Cultivating A Better World. Our mission is to win today while creating a bright
              future.{' '}
            </p>
            <p>
              Cultivating A Better World is difficult, but we can make a difference when we all do our part. As we
              continue to have the privilege of offering genuine food for our communities, our goal is to keep everyone
              safe and informed, since nothing is more important to us than our employees and our guests. Our people are
              the core of who we are and what we stand for. Our team members are at the forefront of our decisions, all
              the while encouraging hope, optimism, affirmation, and ongoing learning.
            </p>
            <p>
              Making the brand more visible and loved; Utilising a disciplined approach to creativity and innovation;
              Leveraging digital capabilities to drive productivity and expand access. Running successful outlets with a
              strong culture that provides great food with integrity while delivering exceptional in-outlet and digital
              experiences.{' '}
            </p>
            <p>
              Our relentless pursuit of ethical choices at all times is what we believe will build trust in us from our
              customers, colleagues, and investors. Responsible business does not exist without accountability. I commit
              us to transparently communicating our goals, and progress, and strengthening priorities as we grow.
            </p>
            <p>
              CRAVELINKS was launched with an unwavering commitment to serving fresh food, and fast delivery, in a
              comfortable atmosphere. Doing the right thing, in the right way, is still what guides the company and our
              approach to corporate responsibility.
            </p>
          </div>
          <div className='designation'>
            - Sagor Biswas
            <img src={signature} alt='' id='signature' />
          </div>
        </div>
      </div>
      <div
        className='separator'
        style={{
          width: '65%',
          height: '0.8px',
          backgroundColor: 'var(--dark-color)',
          margin: '8vh auto',
          opacity: '0.5',
        }}
      ></div>

      <div
        className='separator'
        style={{
          width: '65%',
          height: '0.8px',
          backgroundColor: 'transparent',
          margin: '2vh auto',
          opacity: '0.5',
        }}
      ></div>
      <section>
        <div className='vision-msg'>
          <div className='header'>Our Vision</div>
          <div className='subtext'>
            We want to disrupt the age-old food industry by adopting the latest technological advancement with the help
            of machine learning and Artificial Intelligence and change the spectrum by personalizing food preferences
            according to every customer's needs, again keeping exceptional conditions and full transparency into
            consideration across all our brands, bringing in automation through robotics to reach the ultimate level of
            perfection during preparing the food. We practice a company culture of prioritizing our staff & employers'
            safety and hygiene, also taking complaints actively and turning up with a rapid resolution by maintaining
            consistency.
          </div>
        </div>
      </section>
      <div
        className='separator'
        style={{
          width: '65%',
          height: '0.8px',
          backgroundColor: 'var(--dark-color)',
          margin: '8vh auto',
          opacity: '0.5',
        }}
      ></div>
      <AltAbout
        cname='goal-msg'
        title='Our Goals'
        header='Sustainability is and always will be a strategic priority for CRAVELINKS.'
        msg1='Our future goal is to always achieve sustainability by reducing the level of carbon emission into the atmosphere and adaptation of more eco-friendly biodegradable packaging methods.'
        msg2='Support zero food wastage by helping below poverty line segments by clean food distributional methods and supporting a noble cause.'
        msg3='Increase the percentage of renewable energy sources year after year.'
        msg4='Pilot at least one innovative new packaging design that reduces plastic every year.'
      />
    </section>
  );
};

export default Values;
