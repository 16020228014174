import React from 'react';
import './AltAbout.css';

const AltAbout = (props) => {
  const IsBottomQuote = () => {
    if (props.quote !== undefined) {
      return (
        <div className='bottom-quote'>
          &ldquo;&nbsp;{props.quote}&nbsp;&bdquo;
        </div>
      );
    } else {
      return null;
    }
  };
  return (
    <div className={`alt-about-wrapper ${props.cname}`}>
      <div className='about-title'>{props.title}</div>
      <div className='sec-header'>{props.header}</div>
      <div className='msg-wrapper'>
        <div className='about-msg' id='msg-1'>
          {props.msg1}
        </div>
        <div className='about-msg' id='msg-2'>
          {props.msg2}
        </div>
        <div className='about-msg' id='msg-3'>
          {props.msg3}
        </div>
        <div className='about-msg' id='msg-4'>
          {props.msg4}
        </div>
        <div className='about-msg' id='msg-5'>
          {props.msg5}
        </div>
        <div className='about-msg' id='msg-6'>
          {props.msg6}
        </div>
      </div>
      <IsBottomQuote />
    </div>
  );
};

export default AltAbout;
