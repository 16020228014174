import React from 'react';
import './About.css';



const About = (props) => {
  return (
    <section className='aboutSection paddingSection'>
      <div className={`titleWrapper ${props.cname}`}>

        <section className='TitleSection'>
        {props.title1} <br /> {props.title2}
    </section>
      </div>
     
      <div className='aboutDivWrapper'>
        <div className='aboutDiv col-md-3'>
          <div className='aboutDivTitle'>{props.header1}</div>
          <div className='aboutDivPara'>{props.subHeader1}</div>
        </div>

        <div className='aboutDiv col-md-4'>
          <div className='aboutDivTitle'>{props.header2}</div>
          <div className='aboutDivPara'>{props.subHeader2}</div>
        </div>

        <div className='aboutDiv col-md-3'>
          <div className='aboutDivTitle'>{props.header3}</div>
          <div className='aboutDivPara'>{props.subHeader3}</div>
        </div>
      </div>
      
    </section>
  );
};

export default About;
