import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import Circle from '../../images/circle.svg';
const Footer = () => {
  const circle = document.getElementById('circle-img');

  //   circle.style.transform = 'rotate(180deg)';

  function rotateCircle() {
    circle?.classList.toggle('rotated');
  }
  function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= -10 &&
      rect.left >= -10 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  window.onscroll = function () {
    console.log(isInViewport(document.querySelector('.page-footer')));
  };

  return (
    <footer className='page-footer' onLoad={rotateCircle}>
      <div className='circle'>
        <img src={Circle} id='circle-img' />
      </div>
      <div className='footer-content'>
        <div className='footer-links'>
          <Link to='/home' className='footer-link'>
            HOME
          </Link>
          <Link to='/about' className='footer-link'>
            ABOUT&nbsp;US
          </Link>
          <Link to='/values' className='footer-link'>
            VALUES
          </Link>
          <Link to='/' className='footer-link'>
            ORDER&nbsp;NOW
          </Link>
          <Link to='/gallery' className='footer-link'>
            GALLERY
          </Link>
          <Link to='/contact' className='footer-link'>
            CONTACT&nbsp;US
          </Link>
        </div>
        <div className='separator'></div>
        <div className='subtext'>
          Building a Kitchen Utopia since 2022
          <br />
          &copy; Cravelinks Pvt. Ltd
        </div>
      </div>
      <div className='backToTop' onClick={() => window.scrollTo(0, 0)}>
        Back to top
      </div>
    </footer>
  );
};

export default Footer;
