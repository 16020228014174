import React from 'react';
import Form from '../components/Form/Form';
import Map from '../components/Map/Map';

const Contact = () => {
  window.scrollTo(0,0)
  return (
    <div>
      <Map />
      <Form />
    </div>
  );
};

export default Contact;
