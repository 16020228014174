import React from 'react';
import GalleryComp from '../components/Gallery/Gallery';

const GalleryPage = () => {
  window.scrollTo(0,0)
  return (
    <div>
      <GalleryComp />
    </div>
  );
};

export default GalleryPage;
