import React from 'react'
import './SinglePortfolio.css'
import asset1 from '../../images/galleryMK1.png';
import asset2 from '../../images/asset (2).jpg';
import asset3 from '../../images/asset (3).jpg';
import asset4 from '../../images/asset (4).jpg';
import asset5 from '../../images/asset (5).jpg';

const SinglePortfolio = () => {
  window.scrollTo(0,0)
  return (
    <section className='paddingSection'>
   
   <div className="sectionTitle">THE MAD KNIFE</div>
    
    <div className='gridWrapper'>
    <div className='image-section col-md-4'>
      <img src={asset1} alt='Img1' className='grid-image' />
      <img
        src={asset2}
        alt='Img4'
        className='grid-image grid-col-2 grid-row-2'
      />
      <img src={asset3} alt='Img2' className='grid-image grid-row-2' />
      <img src={asset4} alt='Img5' className='grid-image' />
      <img src={asset5} alt='Img3' className='grid-image' />
    </div>
    <div id='sticky-details col-md-6' className='sticky-section'>
      <div className='main-info'>
        <div id='detail-title'>NEW APPROACH</div>
        <div className='subtext'>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempore
          sapiente nemo vel labore veritatis error quod ullam distinctio sint
          hic.
        </div>
      </div>
      <div className='sub-details'>
        <div className='client'>
          <div className='header'>CLIENT</div>
          <div className='subtext'>Qode Themes</div>
        </div>
        <div className='designers'>
          <div className='header'>DESIGNERS</div>
          <div className='subtext'>Sean Freeman, Ralph Martinez</div>
        </div>
        <div className='date'>
          <div className='header'>DATE</div>
          <div className='subtext'>January 21,2019</div>
        </div>
        <div className='share-links'>
          <div className='header'>SHARE</div>
          <div className='subtext'>Facebook Twitter LinkedIn</div>
        </div>
      </div>
    </div>
        <div className='temptext'></div>
      </div>
    </section>
  )
}

export default SinglePortfolio