export const photos = [
  {
    src: 'https://cascading-ninja-youtube.github.io/mad-knife-menu/gallery/3.png',
    width: 4,
    height: 5,
  },
  {
    src: 'https://cascading-ninja-youtube.github.io/mad-knife-menu/gallery/6.png',
    width: 4,
    height: 5,
  },
  {
    src: 'https://cascading-ninja-youtube.github.io/mad-knife-menu/gallery/2.png',
    width: 4,
    height: 5,
  },
  {
    src: 'https://cascading-ninja-youtube.github.io/mad-knife-menu/gallery/4.png',
    width: 3,
    height: 5,
  },
  {
    src: 'https://cascading-ninja-youtube.github.io/mad-knife-menu/gallery/3.png',
    width: 3,
    height: 4,
  },
  {
    src: 'https://pulaoroast.com/static/media/backgroundmobile.9c6f777c.jpg',
    width: 4,
    height: 5,
  },
  {
    src: 'https://pulaoroast.com/static/media/DSC_9512.302cca16.jpg',
    width: 4,
    height: 5,
  },
  {
    src: 'https://pulaoroast.com/static/media/DSC_0107.b5cfc78a.jpg',
    width: 4,
    height: 5,
  },
  {
    src: 'https://pulaoroast.com/static/media/3.f0659390.jpg',
    width: 4,
    height: 5,
  },
  {
    src: 'https://pulaoroast.com/static/media/1.de1ad5c8.jpg',
    width: 3,
    height: 4,
  },
  {
    src: 'https://cascading-ninja-youtube.github.io/mad-knife-menu/gallery/7.png',
    width: 4,
    height: 5,
  },
  {
    src: 'https://cascading-ninja-youtube.github.io/mad-knife-menu/gallery/8.png',
    width: 4,
    height: 5,
  },
 
];
