import React from 'react'
import SinglePortfolio from '../components/singlePortfolio/SinglePortfolio'

const PortfolioItem = () => {
  window.scrollTo(0,0)
  return (
   <>
   <SinglePortfolio/>
   </>
  )
}

export default PortfolioItem