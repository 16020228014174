import React from 'react';
import About from '../components/About/About';
import Team from '../components/Team/Team';
import AltLanding from '../components/Landing/AltLanding';
import IconCard from '../components/IconCard/IconCard';
import AboutLanding from '../images/aboutLanding.jpg'
const AboutPage = () => {
  window.scrollTo(0,0)
  return (
    <div>
      <AltLanding img={AboutLanding} text="ABOUT US"/>
      <About
        title1={'Redefining the'}
        title2={'food industry'}
        header1={'CraveLinks'}
        subHeader1={
          'We are a customer-centric food-tech startup.Our objective is to blend technology with food and beverage to create a viable solution and revamp an age-old industry. That has otherwise been struggling in an evolving region.'
        }
        header2={'OUR STORY'}
        subHeader2={
          `Cravelinks is more than just a food tech company. it’s a platform that seeks to connect foodies with the best culinary experiences across the nation. Bridging culture and community.
          We at Cravelinks strongly believe food is the only tangible love that is tasted and enjoyed in real-time, something that has no boundaries or religion and we are delighted to spread the love for food across all communities and win people’s hearts by delivering the best craftsmanship with passion. We understand identifying the ideal balance between exquisite culinary craftsmanship and technological infrastructure.`
        }
        header3={'OUR PEOPLE'}
        subHeader3={
          'At CraveLinks we understand in honouring each other’s distinctions and making sure each one of us feels at home. Our team members are at the forefront of our decisions, all the while encouraging hope, optimism, affirmation, and ongoing learning.'
        }
      />
      <div
        className='separator'
        style={{
          width: '65%',
          height: '0.8px',
          backgroundColor: 'var(--dark-color)',
          margin: '8vh auto',
          opacity: '0.5',
        }}
      ></div>
        <About
        cname='aboutReverse'
        title1={'Our Tech'}
        title2={'Grounds'}
        
      />
      {/* <About
        cname='aboutReverse'
        title1={'Our Tech'}
        title2={'Grounds'}
        header1={'PRODUCT'}
        subHeader1={
          'Innovate technology-based solutions that will revolutionize the F&B industry, while positively impacting our customers, restaurant partners, and communities.'
        }
        header2={'ENGINEERING'}
        subHeader2={
          'Our Engineers are always innovating to find a viable solution in personalizing food priorities and advancing the consumer experience. Be part of the pioneering team creating scalable, resilient software solutions.'
        }
        header3={'DATA'}
        subHeader3={
          'At CraveLinks, facilitate data-powered products and resolutions to satisfy the cravings of the nation!'
        }
      /> */}
      <IconCard/>
      {/* <Team /> */}
    </div>
  );
};

export default AboutPage;
