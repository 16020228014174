import React from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import './Form.css';

const Form = () => {
  const slideBtn = () => {};
  return (
    <div className='contactWrapper'>
      <div className='detailsWrapper'>
        <div className='title'>
          Feel&nbsp;Free&nbsp;To
          <br /> Contact Us
        </div>
        <div className='subText'>
        Cravelinks is more than just a food tech company. it’s a platform that seeks to connect foodies with the best culinary experiences across the nation. Bridging culture and community.
        </div>
        <div className='contactInfo'>
          Tel: +(91) 456 7890 <br /> hello@cravelinks.com <br /> Action Area II, Newtown
        </div>
        <div className='socialLinks'>
          <a href='www.google.com' target='_blank' rel='noopener noreferrer'>
          <i class="fa fa-facebook" aria-hidden="true"></i>
          </a>
          <a href='www.google.com' target='_blank' rel='noopener noreferrer'>
            <i class="fa fa-instagram" aria-hidden="true"></i>
          </a>
          <a>| Cravelinks</a>
        </div>
      </div>
      <div className='formWrapper'>
        <form action=''>
          <input type='text' id='name' placeholder='Name' />
          <input type='text' id='email' placeholder='Email' />
          <input type='text' id='msg' placeholder='Your Message' />

          <button type='submit' id='send-mail-btn' onMouseEnter={slideBtn}>
            Send Mail
          </button>
        </form>
      </div>
    </div>
  );
};

export default Form;
