import React, { useState, useRef, useEffect } from "react";
import PRLogoWhite from "../images/PRLogoWhite.png";
import PRLogoBlack from "../images/PRLogoBlack.png";
import MKLogoWhite from "../images/MKLogoWhite.png";
import MKLogoBlack from "../images/MKLogoBlack.png";
const Orders = () => {
  const [mode, setMode] = useState(true);
  var r = document.querySelector(":root");

  // Create a function for getting a variable value
  function getMode() {
    // Get the styles (properties and values) for the root
    var rs = getComputedStyle(r);
    // Alert the value of the --blue variable
    var color = rs.getPropertyValue("--background");
    if (color == "#fff") return true;
    else return false;
  }

  window.onscroll = () => {
    setMode(getMode());
    console.log(mode);
  };
  const PrLogo = useRef();
  const MkLogo = useRef();

  // useEffect(() => {
  //     if(mode)
  //     {
  //        PrLogo.current.src=PRLogoBlack
  //        MkLogo.current.src=MKLogoBlack
  //     }
  //     else
  //     {
  //       PrLogo.current.src=PRLogoWhite
  //       MkLogo.current.src=MKLogoWhite
  //     }

  // }, [mode])

  return (
    <section className="orderSectionWrapper paddingSection">
      <section className="TitleSection">Order Now</section>
      <div className="orderSection">
        <div className="orderCardDiv">
          <img src={PRLogoBlack} alt="" ref={PrLogo} />
          <div className="orderCardDetails">
            <div className="orderCardTitle">Pulao Roast</div>
            <div className="orderCardDesc">Taste that you crave</div>
            <a
              href="https://wa.me/7003885406?text=Hey,%20%0AI'm%20looking%20for%20bul%20orders%20for%20an%20event."
              target="_blank"
              rel="noopener noreferrer"
              className="orderBtn"
            >
              Enquire Bulk Order{" "}
            </a>
          </div>
        </div>

        <div className="orderCardDiv">
          <img src={MKLogoBlack} alt="" ref={MkLogo} />
          <div className="orderCardDetails">
            <div className="orderCardTitle">The Mad Knife</div>
            <div className="orderCardDesc">The Snack Madness</div>
            <a
              href="https://orders.themadknife.com"
              target="_blank"
              rel="noopener noreferrer"
              className="orderBtn"
            >
              Order Now
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Orders;
