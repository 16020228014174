import React from 'react'
import './IconCard.css'
import Data from '../../images/data.png'
import Engineering from '../../images/engineering.png'
import Product from '../../images/product.png'
const IconCard = () => {
  return (
    <section className='paddingSection IconCardWrapper'>
        <div className="IconCard col-md-3">
            <div className="Icon">
                <img src={Data} alt="" className='cardIconImg'/>
            </div>
            <div className="cardTitle">Data</div>
            <div className="cardBody">At CraveLinks, facilitate data-powered products and resolutions to satisfy the cravings of the nation!</div>
        </div>
        <div className="IconCard col-md-3">
            <div className="Icon">
                <img src={Engineering} alt="" className='cardIconImg'/>
            </div>
            <div className="cardTitle">Engineering</div>
            <div className="cardBody">Our Engineers are always innovating to find a viable solution in personalizing food priorities and advancing the consumer experience. Be part of the pioneering team creating scalable, resilient software solutions.
        </div>
        </div>
        <div className="IconCard col-md-3">
            <div className="Icon">
                <img src={Product} alt="" className='cardIconImg'/>
            </div>
            <div className="cardTitle">Product</div>
            <div className="cardBody">Innovate technology-based solutions that will revolutionize the F&B industry, while positively impacting our customers, restaurant partners, and communities.</div>
        </div>
       
    </section>
  )
}

export default IconCard