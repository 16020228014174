import React from 'react';
import './Map.css';

const Map = () => {
  return (
    <div className='mapWrapper' style={{zIndex:99999999}}>
      <iframe
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1548.9061788991185!2d88.48843942686997!3d22.5813287085846!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a020bcc52336b45%3A0x2a992453184374a7!2sTHE%20MAD%20KNIFE!5e0!3m2!1sen!2sin!4v1648970973855!5m2!1sen!2sin'
        loading='lazy'
        referrerpolicy='no-referrer-when-downgrade'
      ></iframe>
    </div>
  );
};

export default Map;
