import React from 'react'
import { Link } from 'react-router-dom';
import './Landing.css'
import $ from 'jquery';
import store from '../../images/store.jpg'
import storeInterior from '../../images/storeInterior.jpg'
import {FaFacebookF,FaInstagram} from 'react-icons/fa'
import ParticleLanding from './ParticleLanding'
const Landing = () => {
    $(function () {
        $(".btn-6")
          .on("mouseenter", function (e) {
            var parentOffset = $(this).offset(),
              relX = e.pageX - parentOffset.left,
              relY = e.pageY - parentOffset.top;
            $(this).find("span").css({ top: relY, left: relX });
          })
          .on("mouseout", function (e) {
            var parentOffset = $(this).offset(),
              relX = e.pageX - parentOffset.left,
              relY = e.pageY - parentOffset.top;
            $(this).find("span").css({ top: relY, left: relX });
          });
        $("[href=#]").click(function () {
          return false;
        });
      });
      
  return (
    <>
   
    <section className='landingSection'>

      <div className="scrollDiv">
        Scroll down 
      </div>
        <div className="landingCenter">

            <div className="landingDetails">
                <div className="navPath">/ CraveLinks</div>
                <h1>Building a <br /> kitchen utopia</h1>
                <Link to="/about" className="btn-6" >Explore<span></span></Link>
            </div>
            <div className="landingImage">
            <div className="circle"></div>
                <img src={store} alt="" id="landingHorizontalImage" />
                 {/* <img src="https://images.pexels.com/photos/1005644/pexels-photo-1005644.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"id='abstractVerticalImage' /> */}
                 <img src={storeInterior}id='abstractVerticalImage' />
            </div>
        </div>
      <div className="socialsDiv">
        <a href="https://www.instagram.com/cravelinks_co/" target="_blank" rel="noopener noreferrer"><FaFacebookF/></a>
        <a href="https://www.facebook.com/groups/486725186335468" target="_blank" rel="noopener noreferrer"><FaInstagram/></a>
        <a style={{fontFamily:'Montserrat,sans-serif',fontWeight:600}}>| Cravelinks</a>
      </div>
    </section>
    </>
  )
}

export default Landing