import React from 'react';
import aboutLanding from '../../images/aboutLanding.jpg';

import './AltLanding.css';

const AboutLanding = (props) => {
  return (
    <div className='about-landing'>
      <div className='landing-center'>
        <div className='landing-image'>
          <img src={props.img} alt='' />
        </div>
        <div className="landing-content">
        {props.text}
        </div>
      </div>
    </div>
  );
};

export default AboutLanding;
